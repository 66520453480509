@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container:global(.ui.input) {
  > i {
    color: $secondaryIconColor;
    opacity: 1 !important;
  }
  > input {
    padding: 0 15px;
    line-height: 30px;
    height: 30px;
    &::placeholder {
      color: $secondaryIconColor;
    }
    &:focus + i {
      color: $baseTextColor;
    }
    &:focus {
      background-color: $searchInputBackground;
      color: $baseTextColor;
      border: 1px solid $searchInputBorder;
    }

    color: $baseTextColor;
    background-color: $searchInputBackground;
    border: 1px solid transparent;
  }
}

.clear {
  flex-shrink: 0;
  margin-left: 2px;
  > i {
    margin: 0 !important;
  }
}

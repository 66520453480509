@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container:global(.ui.attached.segment) {
  position: relative;
  color: $baseTextColor;
  background: $baseBackgroundColor;
  border: none;
  padding: 10px 15px 0;
  width: 100%;
  max-width: 100%;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
}
.container:global(.ui.segment .ui.grid > .column) {
  padding: 0;
  margin: 0;
}

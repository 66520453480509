@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
}

.filterButton {
  cursor: pointer;
  background: none;
  padding: 0;
  outline: none;
  color: $baseIconColor;
  border: 1px solid transparent;
  height: 30px;
  width: 30px;
  margin: 0 3px;
  text-align: center;
  border-radius: 3px;
  position: relative;
  > i {
    margin: 0 !important;
  }

  &:hover,
  &.activeFilter {
    border: 1px solid $baseControlColor;
    color: $invertTextColor;
    background-color: $baseControlColor;
  }
  &.activeFilter {
    border: 1px solid $activeBackgroundColor;
    color: $invertTextColor;
    background-color: $activeBackgroundColor;
  }
}

.activeFilter {
}

.appliedFilter {
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -12px;
    margin-left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $filterActive;
  }
}

.input {
  width: 192px;
  margin: 2px 0;
}

.groupButton {
  margin: 2px !important;
  background-color: $statusBackgroundColor;
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  flex-shrink:0;
  color: $baseIconColor;
  background: none;
  outline: none;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  line-height: 28px;
  width: 28px;
  padding: 0;
  margin: 0;
  border: 1px solid transparent;
  border-radius: 3px;
  > i {
    margin: 0 !important;
  }
  &:hover {
    border-color: $activeBackgroundColor;
    > i {
      color: $activeBackgroundColor;
    }
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  background-color: $baseBackgroundColor;
  display: inline-flex;
  max-width: 100%;
  margin: 0;
  font-family: $baseFontFamily;

  > span {
    padding: 7px 5px;
  }

  span {
    font-size: $baseTextSize;
    font-style: normal;
    line-height: 120%;

    &:first-child {
      width: 30%;
      text-align: right;
      color: $secondaryTextColor;
    }

    &:last-child {
      width: 70%;
      text-align: left;
      color: $baseTextColor;
    }
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  color: $baseTextColor;
  background-color: $baseBackgroundColor;
  padding: 0 13px;
  display: block;
  position: relative;
  border: 1px solid transparent;
  border-bottom: 1px solid $layoutBorderColor;
  transition: all 0.2s ease-in-out;
  position: relative;
  &:hover {
    color: $baseTextColor;
    // box-shadow: 0px 1px 10px $shadowColor;
    border-color: $activeColor;
    z-index: 1000;
  }
}

.inner {
  height: 100%;
  padding: 12px 0 15px 0;
}

.taskId {
  float: left;
  display: block;
  font-weight: bold;
  font-size: $smallTextSize;
}

.date {
  float: right;
  display: block;
  color: $secondaryTextColor;
  font-size: $smallTextSize;
}

.caption {
  display: block;
  clear: both;
  font-weight: bold;
  padding: 5px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.subCaption {
  display: block;
  line-height: 1.2em;
  max-height: 3.5em;
  overflow: hidden;
  position: relative;
  text-align: left;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  // &::before {
  //   position: absolute;
  //   content: "...";
  //   inset-block-end: 0; /* "bottom" */
  //   inset-inline-end: 0; /* "right" */
  //   right: 0;
  //   bottom: 0;
  // }

  // &::after {
  //   /* points in the end */
  //   content: "";
  //   /* absolute position */
  //   position: absolute;
  //   /* set position to right bottom corner of text */
  //   right: 0;
  //   /* set width and height */
  //   width: 1em;
  //   height: 1em;
  //   margin-top: 0.2em;
  //   /* bg color = bg color under block */
  //   background: white;
  // }
}

.creator {
  display: block;
  padding: 5px 0;
  line-height: 30px;
}

.statuses {
  > span {
    display: inline-block;
    font-size: $smallTextSize;
    line-height: 120%;
    background: $statusBackgroundColor;
    color: $statusColor;
    padding: 2px 5px;
    margin: 2px 2px 2px 0;
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  color: $secondaryTextColor;
  background-color: $baseBackgroundColor;
  position: relative;
  width: 100%;
  height: 100%;

  > span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    color: $activeColor;
  }
}

@function px($size) {
  $remSize: $size / 14;
  @return #{$remSize}rem;
}

// https://getbootstrap.com/docs/4.1/layout/overview/
$width-ssm: 480px;
$width-sm: 576px;
$width-md: 768px;
$width-lg: 992px;
$width-xl: 1200px;
$width-xxl: 1440px;

$gap: 0.02;

@mixin screen-up($media) {
  @if $media == sm {
    @media only screen and (min-width: $width-sm) {
      @content;
    }
  } @else if $media == ssm {
    @media only screen and (min-width: $width-ssm) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (min-width: $width-md) {
      @content;
    }
  } @else if $media == lg {
    @media only screen and (min-width: $width-lg) {
      @content;
    }
  } @else if $media == xl {
    @media only screen and (min-width: $width-xl) {
      @content;
    }
  } @else if $media == xxl {
    @media only screen and (min-width: $width-xxl) {
      @content;
    }
  }
}

@mixin screen-down($media) {
  @if $media == sm {
    @media only screen and (max-width: $width-sm - $gap) {
      @content;
    }
  } @else if $media == ssm {
    @media only screen and (max-width: $width-ssm - $gap) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (max-width: $width-md - $gap) {
      @content;
    }
  } @else if $media == lg {
    @media only screen and (max-width: $width-lg - $gap) {
      @content;
    }
  } @else if $media == xl {
    @media only screen and (max-width: $width-xl - $gap) {
      @content;
    }
  } @else if $media == xxl {
    @media only screen and (max-width: $width-xxl - $gap) {
      @content;
    }
  }
}

@mixin screen-only($media) {
  @if $media == xs {
    @media only screen and (max-width: $width-sm - $gap) {
      @content;
    }
  } @else if $media == sm {
    @media only screen and (min-width: $width-sm) and (max-width: $width-md - $gap) {
      @content;
    }
  } @else if $media == ssm {
    @media only screen and (min-width: $width-ssm) and (max-width: $width-sm - $gap) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (min-width: $width-md) and (max-width: $width-lg - $gap) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (min-width: $width-lg) and (max-width: $width-xl - $gap) {
      @content;
    }
  } @else if $media == xl {
    @media only screen and (min-width: $width-xl) and (max-width: $width-xxl - $gap) {
      @content;
    }
  } @else if $media == xxl {
    @media only screen and (min-width: $width-xxl) {
      @content;
    }
  }
}

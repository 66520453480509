$baseFontFamily: "Helvetica Neue", Arial, Helvetica, sans-serif;

$baseTextColor: #333333 !default;
$invertTextColor: #ffffff !default;
$baseBackgroundColor: #ffffff !default;

$baseControlColor: #6992c0 !default;
$baseIconColor: #b7c9dd !default;

$secondaryTextColor: #8c94a1 !default;
$secondaryBackgroundColor: #f8f8fa !default;

$statusColor: #56647a !default;
$statusBackgroundColor: #e8edf5 !default;

$activeBackgroundColor: #3e648f !default;

$activeColor: $activeBackgroundColor !default;

$layoutBorderColor: #e5e5e5 !default;
$topMenuBorderColor: #486e98 !default;
$activeBorderColor: #d73741 !default;

$controlTextColor: #333333 !default;
$controlBackgroundColor: #f8f8fa !default;

$baseTextSize: 1rem;
$smallTextSize: 0.928rem;
$largeTextSize: 1.142rem;
$veryLargeTextSize: 2rem;

//Icon
$secondaryIconColor: #90a0b7 !default;

//Btn
$btnTextColor: #ffffff !default;
$btnBackgroundColorBase: #3e648f !default;
$btnBackgroundColorHover: #4a89d0 !default;

// Login
$loginBackgroundColor: #ffffff !default;

// Menu
$topMenuBackground: #3e648f !default;

// Dynamic fields
$groupHeaderBackground: #f8f8fa !default;

// Chat
$chatIncomingMessageBackground: $baseBackgroundColor !default;
$chatIncomingMessageColor: $baseTextColor !default;
$chatOutcomingMessageBackground: #eef2f9 !default;
$chatOutcomingMessageColor: $baseTextColor !default;
$separatorColor: #ececec !default;
$separatorTextColor: #7b8590 !default;
$chatBgImage: url("../../../assets/chat_bg.jpg") !default;

// Loader
$loaderDimmerColor: #007bff !default;

// Task
$taskStatusFromColor: #f8f8fa !default;
$taskStatusToColor: #beeba8 !default;

// Transitions
$denyTransitionBackground: #ff4a68 !default;
$okTransitionBackground: #68b344 !default;
$agreeTransitionBackground: #e09708 !default;

// Dropzone
$dropzoneDragActive: #85909c !default;

// Search input
$searchInputBackground: #f5f8fa !default;
$searchInputBorder: #c2cfe0 !default;

$filterActive: #ff4a68 !default;

$shadowColor: rgba(0, 0, 0, 0.09) !default;

// Auth
$authIllustration: url("./images/auth-illustration.png") !default;

// Grid
$gridHeaderColor: $separatorTextColor !default;
$gridBorderColor: #EBEFF2;

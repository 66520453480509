@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: $baseBackgroundColor !important;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.container nav,
.container ul {
  margin: 0;
}

.container nav {
  background: #ddd;
  flex-shrink: 0;
}

.container > :nth-child(1) {
  width: 100%;
}

.container > :nth-child(2) {
  flex-grow: 1;
  overflow-y: auto;
}

.body {
  display: flex;
  flex-direction: row;
  position: relative;
}

.menu {
  width: px(260);
  flex-grow: 0;

  flex-shrink: 0;

  &:first-child {
    border-right: 2px solid $layoutBorderColor;
  }

  &:last-child {
    border-left: 2px solid $layoutBorderColor;
  }
}

.mobileMenu {
  position: fixed;
  left: 0;
  top: 40px;
  right: 0;
  bottom: 0;
  width: auto;
  background: $baseBackgroundColor;
  z-index: 999;
  border: none !important;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
  color: $baseTextColor !important;
  background-color: $baseBackgroundColor !important;
}

// Loader
.container {
  div:global(.ui.inverted.dimmer) {
    background-color: transparent;
  }
  div:global(.ui.dimmer) {
    background-color: transparent;
  }
  :global(.ui.active.dimmer .ui.loader) {
    &:after {
      border-color: $loaderDimmerColor transparent transparent;
    }
  }
}

.filterSidebar {
  padding: 0 10px;
  > button:global(.ui.button) {
    width: 100%;
  }
}

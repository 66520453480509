@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container:global(.ui.segment) {
  color: $baseTextColor;
  background: $baseBackgroundColor;
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  width: 100%;
}
.container:global(.ui.attached.segment) {
  width: 100%;
}

.page:global(.ui.segment) {
  padding: 0 1em 1em;
  min-height: 100%;
}

@import "./colors.day.module.scss";

$baseTextColor: #e1e3e6;
$baseBackgroundColor: #19191a;
$layoutBorderColor: #252525;

$baseIconColor: #e1e3e6;
$secondaryTextColor: #8c8f94;
$secondaryBackgroundColor: #363738;
$statusColor: #363738;

$activeBackgroundColor: #4a89d0;
$activeColor: #4a89d0;

$controlTextColor: #f8f8fa;
$controlBackgroundColor: #333333;

//Icon
$secondaryIconColor: #e1e3e6;

// Login
$loginBackgroundColor: #252525;

// Menu
$topMenuBackground: #252525;
$topMenuBorderColor: #19191a;

// Dynamic fields
$groupHeaderBackground: #2f2f2f;

// Task
$taskStatusFromColor: #2f2f2f;
$taskStatusToColor: #5fad6a;

// Chat
$chatIncomingMessageBackground: $baseBackgroundColor;
$chatIncomingMessageColor: $baseTextColor;
$chatOutcomingMessageBackground: #686f79;
$chatOutcomingMessageColor: $baseTextColor;
$chatBgImage: url("../../../assets/chat_bg_night.jpg");

// Search input
$searchInputBackground: #363738;

// Loader

// Task

$shadowColor: #000000;

// Auth
$authIllustration: url("./images/auth-illustration-black.png");

@import "styles/helpers";
@import "styles/colors.day.module";

.container {
  color: $baseTextColor;
  background-color: $baseBackgroundColor;
  &:global(.ui.modal) {
    background-color: $baseBackgroundColor;
  }
}
.container:global(.ui.modal > .actions) {
  background-color: $baseBackgroundColor;
  border-color: $layoutBorderColor;
  padding: 10px 15px;
  text-align: left;
  &:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.container:global(.ui.modal > .actions .ui.button) {
  height: 30px;
  vertical-align: middle;
  background: $btnBackgroundColorBase;
  color: $btnTextColor;
  font-family: $baseFontFamily;
  margin: 0;
  padding: 0 15px;
  font-weight: normal;
  line-height: 1;
  border-radius: 3px;
  &:hover {
    background-color: $btnBackgroundColorHover;
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  width: auto;
  border-radius: 0 !important;
  background-color: $baseBackgroundColor !important;
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0;
  overflow: auto;
}

.menuContainer {
  padding: 10px 0;
  margin: 0;
  li {
    list-style: none;

    a,
    span {
      cursor: pointer;
      position: relative;
      line-height: 120%;
      padding: 7px 15px;
      color: $baseTextColor;
      font-size: $baseTextSize;

      &:hover {
        background-color: $secondaryBackgroundColor;
      }

      display: block;
      white-space: nowrap;
    }
  }
}

.active {
  background-color: $secondaryBackgroundColor;
}

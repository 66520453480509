@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.control:global(.ui.button) {
  background: $btnBackgroundColorBase;
  padding: 0 15px;
  font-size: $smallTextSize;
  text-align: center;
  display: inline-flex;
  height: 30px;
  align-items: center;
  margin: 0;
  border-radius: 3px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:active {
    opacity: 0.8;
  }
  &:hover {
    box-shadow: 0 2px 5px rgba(62, 100, 142, 0.4);
  }

  &,
  &:focus,
  &:active {
    color: $btnTextColor;
    background-color: $btnBackgroundColorBase;
  }
  &:hover {
    background-color: $btnBackgroundColorBase;
    color: $btnTextColor;
  }

  &:global(.primary) {
    background-color: $btnBackgroundColorBase;
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.field {
  display: inline-flex;
  width: 100%;
  margin: 0;
  font-family: $baseFontFamily;

  > label {
    padding-top: 7px;
    padding-right: 0;
    padding-bottom: 7px;
    padding-left: 0;
    font-size: $baseTextSize !important;
    font-style: normal;
    line-height: 120%;
    font-weight: normal !important;

    width: 30%;
    text-align: left;
    color: $secondaryTextColor !important;
    max-width: px(220);
    padding-right: 7px;

    &:last-child {
      width: 70%;
      text-align: left;
      color: $baseTextColor;
      overflow-x: auto;
      padding-left: 7px;
    }
  }

  > :nth-child(2) {
    flex-grow: 1;
  }

  input[disabled] {
    background: #eee !important;
    opacity: 1 !important;
  }

  @include screen-down(lg) {
    display: block;
    padding-bottom: 8px;
    > label {
      padding: 0;
      width: 100%;
      max-width: none;
    }
    > input {
      padding-bottom: 2px;
      display: block;
      width: 100%;
      max-width: none;
      text-align: left;
    }
  }
}

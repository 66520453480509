@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  border-radius: 0 !important;
  background-color: $topMenuBackground !important;
  margin-bottom: 0 !important;
}
.container:global(.ui.inverted.blue.menu .item) {
  &:before {
    background-color: $topMenuBorderColor;
  }
}

.container:global(.ui.menu) {
  height: 40px;
  min-height: 40px;
  margin: 0;
  padding: 0;
}
.container:global(.ui.menu .item) {
  background: none;
  padding: 0;
  text-transform: none;
}

.container:global(.ui.menu i.icon) {
  background: none;
  text-transform: none;
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
  width: 40px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
}
.icon {
  color: $baseIconColor;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 0;
  border: none !important;

  &:hover i {
    color: $invertTextColor;
  }

  &.activeIcon {
    color: $activeColor;
    background-color: $baseBackgroundColor;
    &:hover i {
      color: $baseControlColor;
    }
  }
}

.user {
  padding: 0 10px;
  > a {
    color: $baseIconColor;
    &:hover {
      color: $invertTextColor;
    }
  }
}

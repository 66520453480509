@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.control {
  color: $baseTextColor;
  background-color: $baseBackgroundColor;
  padding: 40px 0 0;
}

.control :global(.ui.secondary.pointing.menu) {
  margin-left: 0;
  margin-right: 0;
  border-bottom: 1px solid $layoutBorderColor;
  background-color: $baseBackgroundColor;
}
.control :global(.ui.secondary.pointing.menu.ui.menu) {
  min-height: auto;
  margin: 0;
}
.control :global(.ui.secondary.menu .item) {
  margin: 0 10px;
  padding: 0;
  height: 36px;
  color: $secondaryTextColor;
}

.control :global(.ui.secondary.pointing.menu .item) {
  margin: 0 15px;
  padding: 0;
  border-bottom-width: 3px;
  background-color: transparent;
  border-color: transparent;

  &:hover {
    color: $baseTextColor;
  }
}
.control :global(.ui.secondary.pointing.menu .active.item) {
  background-color: transparent;
  box-shadow: none;
  border-color: $activeColor;
  font-weight: normal;
  color: $activeColor;
  border-bottom-width: 3px;

  &:hover {
    border-color: $activeColor;
    color: $activeColor;
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $baseTextColor;
  background-color: $loginBackgroundColor;
  padding: 0 100px 0 100px;
}

.form {
  flex: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.illustration_container {
  width: 60%;
  height: 100%;
}

.illustration {
  width: 100%;
  height: 100%;
  background: $authIllustration center center no-repeat no-repeat;
  background-size: 100%;
  @include screen-down(lg) {
    background: none;
  }
}

.logo_container {
  margin-bottom: 50px;
}

.logo {
  width: px(200);
}

.greeting {
  font-size: px(18);
  margin-bottom: 10px;
}

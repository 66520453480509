@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  color: $baseTextColor;
  background-color: $baseBackgroundColor;
  padding: 10px 0;

  .menuItem {
    padding: 10px 0;
    height: 54px;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;
    &:hover {
      // box-shadow: 0px 1px 10px $shadowColor;
      border-color: $activeColor;
      h3 {
        color: $baseTextColor !important;
      }
    }
    &:after {
      content: "";
      display: block;
      width: 3px;
      background-color: transparent;
      position: absolute;
      left: -1px;
      top: -1px;
      bottom: -1px;
    }

    h3:global(.ui.header) {
      display: flex;
      margin: 0;
      padding: 0;
      font-size: inherit;
      line-height: 1;
      font-weight: normal;
      align-items: center;

      &:first-child,
      &:last-child {
        margin: 0;
      }
    }
    h3:global(.ui.header > .icon) {
      display: inline-block;
      opacity: 1;
      font-size: 1.3rem;
      padding: 0;
      margin: 0;
      width: 40px;
      height: auto;
      color: $baseIconColor;
    }
    h3:global(.ui.header > .content) {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      color: $secondaryTextColor;

      &:first-child {
        padding-bottom: 4px;
      }
    }
    h3:global(.ui.header > .content div:first-child) {
      padding-bottom: 4px;
      font-weight: bold;
      color: $secondaryTextColor;
    }
    h3:global(.ui.header > .content .sub.header) {
      color: $secondaryTextColor;
    }
  }

  .active {
    border-left-color: $activeColor;
    h3:global(.ui.header > .content div:first-child),
    h3:global(.ui.header > .content .sub.header) {
      color: $activeColor !important;
    }
    h3:global(.ui.header > .icon) {
      color: $activeColor;
    }

    &:after {
      background-color: $activeBackgroundColor;
    }
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  margin: 10px 0;
}

.groupTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  background-color: $baseBackgroundColor;
  display: inline-flex;
  width: 100%;
  margin: 0;
  font-family: $baseFontFamily;

  > span {
    padding-top: 7px;
    padding-right: 0;
    padding-bottom: 7px;
    padding-left: 0;
    font-size: $baseTextSize;
    font-style: normal;
    line-height: 120%;

    &:first-child {
      width: 30%;
      text-align: left;
      color: $secondaryTextColor;
      max-width: px(220);
      padding-right: 7px;
    }

    &:last-child {
      width: 70%;
      text-align: left;
      color: $baseTextColor;
      overflow-x: auto;
      padding-left: 7px;
    }
  }

  @include screen-down(lg) {
    display: block;
    padding-bottom: 8px;
    > span {
      padding: 0;
      &:first-child {
        padding-bottom: 2px;
        display: block;
        width: 100%;
        max-width: none;
        text-align: left;
      }
    }
  }
}

.verticalMode {
  display: block;
  padding-bottom: 8px;
  > span {
    padding: 0;
    &:first-child {
      padding-bottom: 2px;
      display: block;
      width: 100%;
      max-width: none;
      text-align: left;
    }
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.basicToolbar {
  padding: 5px 15px 3px;
  border-bottom: 1px solid $layoutBorderColor;
  display: flex;
  justify-content: space-between;
}

.noOutline {
  outline: none !important;
}

@mixin button {
  padding: 0 15px;
  font-size: $smallTextSize;
  text-align: center;
  display: inline-flex;
  height: 30px;
  align-items: center;
  margin: 0;
  border-radius: 3px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:active {
    opacity: 0.8;
  }
  &:hover {
    box-shadow: 0 2px 5px rgba(62, 100, 142, 0.4);
  }
}

@mixin activeButton {
  &,
  &:focus,
  &:active {
    color: $btnTextColor;
    background-color: $btnBackgroundColorBase;
  }
  &:hover {
    background-color: $btnBackgroundColorBase;
    color: $btnTextColor;
  }
}

@mixin toggleButton {
  &,
  &:focus,
  &:active,
  &:hover {
    color: $statusColor;
    background-color: $statusBackgroundColor;
  }
}

.basicBtn:global(.ui.button) {
  @include button;
  @include activeButton;
  svg {
    margin-right: 9px;
  }
}

.toggleBtn:global(.ui.button) {
  @include button;
  @include toggleButton;

  > i {
    margin-left: 14px !important;
    margin-right: 0 !important;
  }
}

.activeToggleBtn:global(.ui.button) {
  @include activeButton;

  > i {
    color: $invertTextColor !important;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  > div:last-child {
    flex: 1 1 auto;
    overflow: auto;
    min-height: auto !important;
  }
}

.w100 {
  width: 100%;
}

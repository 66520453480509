@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  color: $baseTextColor;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-color: $searchInputBackground;
  border-radius: 50%;
  margin-right: 10px;

  > span {
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.userName {
  white-space: nowrap;
  > span,
  > a > span {
    // display: inline-block;
    vertical-align: middle;
    padding-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.userName.standard {
  > span,
  > a > span {
    font-size: $baseTextSize;
  }
}

.medium {
  width: 150px;
  height: 150px;
  margin-right: 0;
}

.boxy {
  > span {
    border-radius: 0;
  }
}

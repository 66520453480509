@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.panelTitle {
  color: $secondaryTextColor;
  font-weight: bold;
  font-size: $smallTextSize;
  margin: 10px 10px 5px;
}

.panelContainer {
  width: 100%;
  height: 100%;
  overflow: auto;

  .ui.accordion {
    padding-top: 10px;
  }
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  min-height: px(580);
}

.avatarContainer {
  display: flex;
  justify-content: center;
}

.schedule {
  :global(.DayPicker-Month) {
    border-spacing: 4px;
    border-collapse: separate;
    :global(.DayPicker-Day) {
      padding: 0;
    }
  }
}

.scheduleDay {
  padding: 7px;
}

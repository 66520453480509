@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
  color: $baseTextColor;
  background-color: $baseBackgroundColor;
}

.grid {
  overflow-y: auto;
}

@import "styles/helpers.scss";
@import "styles/colors.day.module.scss";

.container {
}

.header {
  @include screen-up(lg) {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
  }
  .info {
    margin-left: 15px;
    flex-grow: 1;
    .middleName {
      font-size: $largeTextSize;
      line-height: 120%;
      padding-bottom: 10px;
      display: inline-block;
    }
    @include screen-down(lg) {
      margin-top: 10px;
    }
  }
  .status {
    font-size: px(13);
    line-height: 120%;
    display: inline-block;
  }
  .contactActions {
    > a {
      color: $baseIconColor;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      font-size: px(22);

      &:hover {
        color: $activeColor;
      }
    }
  }
  .upperDivider {
    @include screen-down(lg) {
      border: none !important;
    }
  }
  .bottomDivider {
    @include screen-up(lg) {
      display: none;
    }
  }
}
